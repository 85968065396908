import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const API_URL = process.env.REACT_APP_API_Link;

const puttStateList = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/state/add",
    {
      name: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getStateNameById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/get-name-by-id/" + id, {
    headers: headers,
  });
};
const updateStateNameById = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/state/update/" + id,
    {
      name: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getStateList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/listAdmin", {
    headers: headers,
  });
};

const getPaginationStateList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/state/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitStateList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchStateList = (col, val, limit) => {
  if (col === "1") {
    col = "id";
  } else if (col === "2") {
    col = "title";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/state/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const puttCityList = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/city/add",
    {
      name: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getCityInfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/get-info-by-id/" + id, {
    headers: headers,
  });
};
const updateCityList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/city/update/" + id,
    {
      name: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getCitySelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/list", {
    headers: headers,
  });
};
const getCityList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/listAdmin", {
    headers: headers,
  });
};

const getNewLimitCityList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchCityList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/city/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginationCityList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/city/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getDosList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos", {
    headers: headers,
  });
};

const getDosSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/dos", {
    headers: headers,
  });
};

const getPaginationDosList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitDosList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchDosList = (col, val, limit) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "last_name";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNdosList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos", {
    headers: headers,
  });
};
const getNdosSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos", {
    headers: headers,
  });
};

const getPaginationNdosList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-ndos" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitNdosList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchNdosList = (col, val, limit) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "last_name";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-ndos" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const puttStoreList = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/add",
    {
      name: values.storeName,
      address: values.storeAddress,
      zip: values.zipCode,
      account: values.storeAccount,
      dos: values.dos,
      ndos: values.ndos,
      city_id: values.city,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const updateStoreList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/update/" + id,
    {
      name: values.storeName,
      address: values.storeAddress,
      zip: values.zipCode,
      account: values.storeAccount,
      dos: values.dos,
      ndos: values.ndos,
      city_id: values.city,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};
const uploadStoreList = (fileUp) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(API_URL + "store/import", fileUp, headers);
};
const getStoreList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/listAdmin", {
    headers: headers,
  });
};

const getPaginationStoreList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitStoreList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchStoreList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "address";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/listAdmin" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getStoreinfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/get-by-id-admin/" + id, {
    headers: headers,
  });
};

const getLicenseeRsaList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-rsa", {
    headers: headers,
  });
};
const getNewLimitLicenseeRsaList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-rsa" + "?limit=" + limit, {
    headers: headers,
  });
};
const getPaginationLicenseeRsaList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-rsa" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getSearchLicenseeRsaList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  } else {
    col = "created_at";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-rsa?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchLicenseeRsaByDate = (col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-rsa?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const deleteParticipantsData = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};

const deleteSelectedParticipantsData = (recordsId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/delete-selected", {
    headers: headers,
    data: {
      user_id: recordsId,
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};

const getAcceptedRsaList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos-rsa/" + id, {
    headers: headers,
  });
};
const getNewLimitAcceptedRsaList = (limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos-rsa/" + id + "?limit=" + limit, {
    headers: headers,
  });
};
const getPaginationAcceptedRsaList = (pageNo, limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos-rsa/" + id + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getSearchAcceptedRsaList = (col, val, limit, pageNo, id) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  } else {
    col = "created_at";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-rsa/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchAcceptedRsaByDate = (
  col,
  val,
  createDate,
  limit,
  pageNo,
  id
) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-rsa/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getManagerList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-manager", {
    headers: headers,
  });
};
const getNewLimitManagerList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-manager" + "?limit=" + limit, {
    headers: headers,
  });
};
const getPaginationManagerList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-manager" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getSearchManagerList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  } else {
    col = "created_at";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-manager" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchManagerByDate = (col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-manager?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getAcceptedManagerList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos-manager/" + id, {
    headers: headers,
  });
};
const getAcceptedNewLimitManagerList = (limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos-manager/" + id + "?limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getAcceptedPaginationManagerList = (pageNo, limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-manager/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getAcceptedSearchManagerList = (col, val, limit, pageNo, id) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  } else {
    col = "created_at";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-manager/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getAcceptedSearchManagerByDate = (
  col,
  val,
  createDate,
  limit,
  pageNo,
  id
) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-manager/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getCourseList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/listCourses", {
    headers: headers,
  });
};

const getCourseSlideById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/courseIntroById/" + id, {
    headers: headers,
  });
};

const updateCourseStatusByCode = (flag, code) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "course/update-status-by-code/" + code,
    {
      flag: flag,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const deleteCourseSlideById = (recordsId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "course/delete-by-id/" + recordsId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};

const getPaginationCourseList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "course/listCourses" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitCourseList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/listCourses" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchCourseList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "code";
  } else if (col === "3") {
    col = "flag";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "course/listCourses" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getTerritoriesList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listAdmin", {
    headers: headers,
  });
};

const getSearchTerritoriesList = (
  limit,
  st_name,
  st_address,
  st_state,
  st_city,
  c_zip,
  st_acc,
  st_dos,
  st_ndos,
  st_card,
  pageNo
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/territory/listAdmin?limit=" +
      limit +
      "&title=" +
      st_name +
      "&page=" +
      pageNo +
      "&store_address=" +
      st_address.replaceAll("#", "%23") +
      "&zip=" +
      c_zip +
      "&state=" +
      st_state +
      "&account=" +
      st_acc +
      "&dos=" +
      st_dos +
      "&ndos=" +
      st_ndos +
      "&city=" +
      st_city +
      "&visa_card=" +
      st_card,
    {
      headers: headers,
    }
  );
};

const getAccountSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listAccounts", {
    headers: headers,
  });
};
const getZipSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listZip", {
    headers: headers,
  });
};
const getStoreNameSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listStoreName", {
    headers: headers,
  });
};
const getStoreAddressSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listStoreAddress", {
    headers: headers,
  });
};
const getLicenseeGroup = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/licenseeGroup", {
    headers: headers,
  });
};
const getStateByLicenseeGroup = (val) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "quiz/get-state-by-licensee",
    {
      name: val,
    },
    {
      headers: headers,
    }
  );
};

const getQuizList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listResultAdmin", {
    headers: headers,
  });
};

const getPaginationQuizList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "quiz/listResultAdmin?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getNewLimitQuizList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listResultAdmin?limit=" + limit, {
    headers: headers,
  });
};

const getSearchQuizList = (col, val, limit) => {
  if (col === "1") {
    col = "store_name";
  } else if (col === "2") {
    col = "store_state";
  } else if (col === "3") {
    col = "store_city";
  } else if (col === "4") {
    col = "sales_fname";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "sales_lname";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getSearchQuizListWithPagination = (col, val, pageNo, limit) => {
  if (col === "1") {
    col = "store_name";
  } else if (col === "2") {
    col = "store_state";
  } else if (col === "3") {
    col = "store_city";
  } else if (col === "4") {
    col = "sales_fname";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "sales_lname";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getSearchQuizListWithchangeVal = (st_rsa, st_state, st_city, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultAdmin?limit=" +
      limit +
      "&store_state=" +
      st_state +
      "&store_city=" +
      st_city +
      "&store_name=" +
      st_rsa,
    {
      headers: headers,
    }
  );
};

const getDosQuizList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listResultDos/" + id, {
    headers: headers,
  });
};

const getDosPaginationQuizList = (pageNo, limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultDos/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getDosNewLimitQuizList = (limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listResultDos/" + id + "?limit=" + limit, {
    headers: headers,
  });
};

const getDosSearchQuizList = (col, val, limit, id) => {
  if (col === "1") {
    col = "store_name";
  } else if (col === "2") {
    col = "store_state";
  } else if (col === "3") {
    col = "store_city";
  } else if (col === "4") {
    col = "sales_fname";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "sales_lname";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultDos/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getDosSearchQuizListWithPagination = (col, val, pageNo, limit, id) => {
  if (col === "1") {
    col = "store_name";
  } else if (col === "2") {
    col = "store_state";
  } else if (col === "3") {
    col = "store_city";
  } else if (col === "4") {
    col = "sales_fname";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "sales_lname";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultDos/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getDosSearchQuizListWithchangeVal = (
  st_rsa,
  st_state,
  st_city,
  limit,
  id
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultDos/" +
      id +
      "?limit=" +
      limit +
      "&store_state=" +
      st_state +
      "&store_city=" +
      st_city +
      "&store_name=" +
      st_rsa,
    {
      headers: headers,
    }
  );
};

const getDosTerritoriesList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listDos/" + id, {
    headers: headers,
  });
};

const getNewLimitDosTerritoriesList = (limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/territory/listDos/" + id + "?limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getSearchDosTerritoriesList = (col, val, limit, id, pageNo) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "store_address";
  } else if (col === "3") {
    col = "state";
  } else if (col === "4") {
    col = "city";
  } else if (col === "5") {
    col = "zip";
  } else if (col === "6") {
    col = "account";
  } else if (col === "7") {
    col = "dos";
  } else if (col === "8") {
    col = "visa_card";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/territory/listDos/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getPaginationDosTerritoriesList = (pageNo, limit, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/territory/listDos/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/login-admin",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const forgotPass = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/forgot-admin",
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const decryptPasswordByAdmin = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/decrypt-password-admin",
    {
      admin_id: values.adminId,
      admin_password: values.adminPassword,
      user_password: values.userPassword,
    },
    {
      headers: headers,
    }
  );
};
const addUserState = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/state/add",
    {
      name: values.stateName,
      code: values.code,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const addUserCity = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/city/add",
    {
      city: values.cityName,
 
      state_id: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getStateNameByIdUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/get-name-by-id/" + id, {
    headers: headers,
  });
};

const updateStateNameByIdUser = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/state/update/" + id,
    {
      name: values.stateName,
      code: values.code,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getNewLimitCityListUser = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchCityListUser = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/city/listAdmin" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getPaginationCityListUser = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/city/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getCityListUser = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/listAdmin", {
    headers: headers,
  });
};

const getCityInfoByIdUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/get-info-by-id/" + id, {
    headers: headers,
  });
};
const updateCityListUser = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/city/update/" + id,
    {
      city: values.cityName,
 
      state_id: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const deleteUserCity = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/city/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};

const deleteUserState = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/state/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};
const deleteStoreState = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "store/state/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};
const deleteStoreCity = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "store/city/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};
const getNewLimitUserStateList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchUserStateList = (col, val, limit) => {
  if (col === "1") {
    col = "id";
  } else if (col === "2") {
    col = "title";
  } else if (col === "3") {
    col = "code";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/state/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginationUserStateList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/state/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getUserStateList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/listAdmin", {
    headers: headers,
  });
};
const AdminListService = {
  login,
  addUserCity,
  addUserState,
  getStateNameByIdUser,
  updateStateNameByIdUser,
  getNewLimitCityListUser,
  getSearchCityListUser,
  getPaginationCityListUser,
  getCityListUser,
  getCityInfoByIdUser,
  updateCityListUser,
  deleteUserCity,
  deleteUserState,
  deleteStoreState,
  deleteStoreCity,
  getNewLimitUserStateList,
  getSearchUserStateList,
  getPaginationUserStateList,
  getUserStateList,
  forgotPass,
  decryptPasswordByAdmin,
  getStateList,
  puttStateList,
  getStateNameById,
  updateStateNameById,
  getPaginationStateList,
  getNewLimitStateList,
  getSearchStateList,
  puttCityList,
  getCityInfoById,
  updateCityList,
  getCitySelectOptions,
  getCityList,
  getSearchCityList,
  getNewLimitCityList,
  getPaginationCityList,
  getDosList,
  getDosSelectOptions,
  getPaginationDosList,
  getNewLimitDosList,
  getSearchDosList,
  getNdosList,
  getNdosSelectOptions,
  getPaginationNdosList,
  getNewLimitNdosList,
  getSearchNdosList,
  puttStoreList,
  updateStoreList,
  uploadStoreList,
  getStoreList,
  getPaginationStoreList,
  getNewLimitStoreList,
  getSearchStoreList,
  getStoreinfoById,
  getLicenseeRsaList,
  getSearchLicenseeRsaByDate,
  getSearchLicenseeRsaList,
  getPaginationLicenseeRsaList,
  getNewLimitLicenseeRsaList,
  deleteParticipantsData,
  deleteSelectedParticipantsData,
  getAcceptedRsaList,
  getNewLimitAcceptedRsaList,
  getPaginationAcceptedRsaList,
  getSearchAcceptedRsaList,
  getSearchAcceptedRsaByDate,
  getManagerList,
  getNewLimitManagerList,
  getPaginationManagerList,
  getSearchManagerList,
  getSearchManagerByDate,
  getAcceptedManagerList,
  getAcceptedNewLimitManagerList,
  getAcceptedPaginationManagerList,
  getAcceptedSearchManagerList,
  getAcceptedSearchManagerByDate,
  getCourseSlideById,
  deleteCourseSlideById,
  getCourseList,
  getPaginationCourseList,
  updateCourseStatusByCode,
  getNewLimitCourseList,
  getSearchCourseList,
  getTerritoriesList,
  getSearchTerritoriesList,
  getAccountSelectOptions,
  getZipSelectOptions,
  getStoreNameSelectOptions,
  getStoreAddressSelectOptions,
  getLicenseeGroup,
  getStateByLicenseeGroup,
  getQuizList,
  getPaginationQuizList,
  getNewLimitQuizList,
  getSearchQuizList,
  getSearchQuizListWithPagination,
  getSearchQuizListWithchangeVal,
  getDosQuizList,
  getDosNewLimitQuizList,
  getDosPaginationQuizList,
  getDosSearchQuizList,
  getDosSearchQuizListWithPagination,
  getDosSearchQuizListWithchangeVal,
  getDosTerritoriesList,
  getNewLimitDosTerritoriesList,
  getSearchDosTerritoriesList,
  getPaginationDosTerritoriesList,
};

export default AdminListService;
