import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./components/Users/Login/Login";
import Diploma from "./components/Users/Diploma/Diploma";
import ForgotPass from "./components/Users/ForgotPass/ForgotPass";
import Registration from "./components/Users/Registration/Registration";
import Welcome from "./components/Users/Welcome/Welcome";
import TermAndConditions from "./components/Users/PrivacyAndTerms/TermAndConditions";
import CheckForgotToken from "./components/Users/ForgotPass/CheckForgotToken";
import ChangePass from "./components/Users/ChangePass/ChangePass";
import GetPassedResult from "./components/Users/GetPassedResult/GetPassedResult";
import ProfileInfo from "./components/Users/ProfileInfo/ProfileInfo";
import { useContext, useEffect } from "react";
import Faq from "./components/Users/Faq/Faq";

import { UserContext } from "./context/UserContext";
import Course from "./components/Users/Course/Course";
import Quiz from "./components/Users/Quiz/Quiz";
import PassResult from "./components/Users/Results/PassResult";

import PrivacyStatement from "./components/Users/PrivacyAndTerms/PrivacyStatement";
import NotFound from "./components/Users/NotFound/NotFound";

import Dashboard from "./components/Admin/Dashboard";
import LoginAdmin from "./components/Admin/Login/LoginAdmin";
import AddCourse from "./components/Admin/Courses/AddCourse";
import ManageCourse from "./components/Admin/Courses/ManageCourse";
import ManageTerritory from "./components/Admin/Stores/ManageTerritory";
import ManageDoc from "./components/Admin/Stores/ManageDoc";
import ManageNdoc from "./components/Admin/Stores/ManageNdoc";
import ViewLicensedRsa from "./components/Admin/Participants/ViewLicensedRsa";
import ViewManagers from "./components/Admin/Participants/ViewManagers";
import QuizResult from "./components/Admin/QuizResult/QuizResult";
import ProfileAdmin from "./components/Admin/ProfileAdmin/ProfileAdmin";
import AddStates from "./components/Admin/States/AddStates";
import ViewAllStates from "./components/Admin/States/ViewAllStates";
import AddCities from "./components/Admin/Cities/AddCities";
import ViewAllCities from "./components/Admin/Cities/ViewAllCities";
import AddStore from "./components/Admin/Store/AddStore";
import ViewAllStores from "./components/Admin/Store/ViewAllStores";
import UploadStores from "./components/Admin/Store/UploadStores";
import EditState from "./components/Admin/States/EditState";
import EditCity from "./components/Admin/Cities/EditCity";
import EditStore from "./components/Admin/Store/EditStore";
import EditCourse from "./components/Admin/Courses/EditCourse";
import AdminForgotPass from "./components/Admin/ForgotPass/AdminForgotPass";
import AdminCheckForgotToken from "./components/Admin/ForgotPass/AdminCheckForgotToken";
import AdminChangePass from "./components/Admin/ChangePass/AdminChangePass";
import Templete_1 from "./components/Admin/Courses/Templetes/Templete_1";
import Templete_2 from "./components/Admin/Courses/Templetes/Templete_2";
import Templete_3 from "./components/Admin/Courses/Templetes/Templete_3";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import SlideSelect from "./components/Admin/Courses/Templetes/SlideSelect";
import ViewAcceptedRsa from "./components/Admin/ManageRsaDos/ViewAcceptedRsa";
import ViewAcceptedManagers from "./components/Admin/ManageRsaDos/ViewAcceptedManagers";
import ManageDosTerritory from "./components/Admin/Stores/ManageDosTerritory";
import ViewRsa from "./components/Admin/Participants/ViewRsa";
import AddUserStates from "./components/Admin/UserStates/AddUserStates";
import ViewAllUserStates from "./components/Admin/UserStates/ViewAllUserStates";
import AddUserCities from "./components/Admin/UserCities/AddUserCities";
import ViewAllUserCities from "./components/Admin/UserCities/ViewAllUserCities";
import EditUserCity from "./components/Admin/UserCities/EditUserCity";
import EditUserState from "./components/Admin/UserStates/EditUserState";
function App() {
  const navigate = useNavigate();
  const locat = useLocation();
  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { id, accessToken, uType } = state;

  let decoded;

  useEffect(() => {
    // auto logout code start
    if (accessToken) {
      decoded = jwt_decode(accessToken);
      // console.log("expiry date:" + decoded.exp);
      // console.log("current date:" + Math.floor(new Date().getTime() / 1000));
      // var date = new Date(decoded.exp * 1000);
      // console.log(date.toLocaleTimeString("en-US"));
      // const current = new Date();
      // const time = current.toLocaleTimeString("en-US");
      // console.log(time);
    }
    // auto logout code end
    if (
      accessToken &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos"
    ) {
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/");
      }
      if (
        locat.pathname === "/" ||
        locat.pathname === "/forgot_pass" ||
        locat.pathname === "/registration" ||
        locat.pathname === "/forgot_pass/" ||
        locat.pathname === "/registration/"
      ) {
        navigate("/welcome");
      }
    } else {
      if (
        locat.pathname !== "/forgot_pass" &&
        locat.pathname !== "/forgot_pass/" &&
        locat.pathname !== "/forgot_token" &&
        locat.pathname !== "/change_pass" &&
        locat.pathname !== "/termAndConditions" &&
        locat.pathname !== "/privacyStatement" &&
        locat.pathname !== "/registration" &&
        locat.pathname.indexOf("admin") <= -1
      ) {
        navigate("/");
      }
    }

    // admin login conditions
    const callFun = window["onUrlChange"];
    if (accessToken && uType !== "rsa" && uType !== "manager") {
      // auto logout code start
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/admin");
      }
      // auto logout code end
      if (
        locat.pathname === "/admin" ||
        locat.pathname === "/admin/" ||
        locat.pathname === "/admin/forgot_pass" ||
        locat.pathname === "/admin/forgot_pass/"
      ) {
        callFun();
        navigate("/admin/dashboard");
      }
    } else {
      if (
        locat.pathname !== "/admin/forgot_pass" &&
        locat.pathname !== "/admin/forgot_token" &&
        locat.pathname !== "/admin/change_pass" &&
        locat.pathname !== "/admin/forgot_pass/" &&
        locat.pathname !== "/admin/change_pass/" &&
        locat.pathname.indexOf("admin") > -1
      ) {
        navigate("/admin");
      }
    }
  }, [locat.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/certificate" element={<Diploma />} />
      <Route path="/forgot_pass" element={<ForgotPass />} />
      <Route path="/forgot_token" element={<CheckForgotToken />} />
      <Route path="/change_pass" element={<ChangePass />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/profileInfo" element={<ProfileInfo />} />
      <Route path="/termAndConditions" element={<TermAndConditions />} />
      <Route path="/GetPassedResult" element={<GetPassedResult />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/course/:id" element={<Course />} />
      <Route path="/quiz/:id" element={<Quiz />} />
      <Route path="/passResult/:code" element={<PassResult />} />
      {/* <Route path="/failResult" element={<FailResult />} /> */}
      <Route path="/privacyStatement" element={<PrivacyStatement />} />

      {/* admin routes from here */}
      <Route path="/admin" element={<LoginAdmin />} />
      <Route path="/admin/forgot_pass" element={<AdminForgotPass />} />
      <Route path="/admin/forgot_token" element={<AdminCheckForgotToken />} />
      <Route path="/admin/change_pass" element={<AdminChangePass />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/manage_courses" element={<ManageCourse />} />
      <Route path="/admin/add_courses" element={<AddCourse />} />
      <Route path="/admin/manage_territory" element={<ManageTerritory />} />
      <Route
        path="/admin/manage_dos_territory"
        element={<ManageDosTerritory />}
      />
      <Route path="/admin/manage_dos" element={<ManageDoc />} />
      <Route path="/admin/manage_vp" element={<ManageNdoc />} />
      <Route path="/admin/view_licensed_rsa" element={<ViewLicensedRsa />} />
      {/* demo routes */}
      <Route path="/admin/view-rsa" element={<ViewRsa />} />
      {/* demo routes */}

      <Route path="/admin/view_accepted_rsa" element={<ViewAcceptedRsa />} />
      <Route path="/admin/view_manager" element={<ViewManagers />} />
      <Route
        path="/admin/view_accepted_manager"
        element={<ViewAcceptedManagers />}
      />
      <Route path="/admin/view_quiz_results" element={<QuizResult />} />
      <Route path="/admin/profile_admin" element={<ProfileAdmin />} />
      <Route path="/admin/add_state" element={<AddStates />} />
      <Route path="/admin/view_all_states" element={<ViewAllStates />} />
      <Route path="/admin/add-user-state" element={<AddUserStates />} />
      <Route path="/admin/view-user-states" element={<ViewAllUserStates />} />
      <Route path="/admin/add_city" element={<AddCities />} />
      <Route path="/admin/view_all_cities" element={<ViewAllCities />} />
      <Route path="/admin/add-user-city" element={<AddUserCities />} />
      <Route path="/admin/view-user-cities" element={<ViewAllUserCities />} />
      <Route path="/admin/add_store" element={<AddStore />} />
      <Route path="/admin/view_all_stores" element={<ViewAllStores />} />
      <Route path="/admin/upload_store" element={<UploadStores />} />
      <Route path="/admin/edit_state/:id" element={<EditState />} />
      <Route path="/admin/edit_city/:id" element={<EditCity />} />
      <Route path="/admin/edit-user-state/:id" element={<EditUserState />} />
      <Route path="/admin/edit-user-city/:id" element={<EditUserCity />} />
      <Route path="/admin/edit_store/:id" element={<EditStore />} />
      <Route path="/admin/edit_courses/:id" element={<EditCourse />} />
      <Route
        path="/admin/add_courses/slideTemplates"
        element={<SlideSelect />}
      />
      <Route path="/admin/add_courses/templete-1" element={<Templete_1 />} />
      <Route path="/admin/add_courses/templete-2" element={<Templete_2 />} />
      <Route path="/admin/add_courses/templete-3" element={<Templete_3 />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
